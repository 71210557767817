import React from "react"
import Template from "../components/templates/template"
import { graphql } from "gatsby"
import { Trans } from "react-i18next"

const NotFound = () => {
  return (
    <Template isHeaderAffected="false">
      <section className="counter-numeric-grid container mx-auto my-10 md:my-20 text-center">
        <h2 className="font-philosopher text-4xl"><Trans>Page Not found.</Trans></h2>
        <p><Trans>{`Sorry we can't find the page you're looking for...`}</Trans></p>
      </section>
    </Template>
  )
}

export const query = graphql`
  query PageQuery($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default NotFound